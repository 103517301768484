import "core-js/modules/es.array.push.js";
require('echarts/theme/macarons'); // echarts theme
import resize from '@/mixins/resize';
import { debounce } from 'throttle-debounce';
export default {
  name: "Chart",
  mixins: [resize],
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    title: {
      type: String,
      default: ''
    },
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '350px'
    },
    chartData: {
      type: Object,
      required: true
    }
  },
  data: function data() {
    return {
      chart: null
    };
  },
  watch: {
    chartData: {
      deep: true,
      handler: function handler(val) {
        this.setOptions(val);
        this.chart.resize();
      }
    }
  },
  mounted: function mounted() {
    var _this = this;
    this.$nextTick(function () {
      _this.initChart();
    });
  },
  beforeDestroy: function beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  },
  methods: {
    initChart: function initChart() {
      var _this2 = this;
      this.chart = this.$echarts.init(this.$el, 'macarons');
      this.setOptions(this.chartData);
      this.chart.on('click', function (params) {
        _this2.$emit('getPointDate', params.dataIndex);
      });
      var debounceFunc = debounce(500, function (params) {
        _this2.$emit('getRangeDate', params.start, params.end);
      });
      this.chart.on('datazoom', function (params) {
        debounceFunc(params);
      });
    },
    setOptions: function setOptions() {
      var _this$chart2, _this$chart3;
      var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
        data = _ref.data,
        loading = _ref.loading,
        total = _ref.total,
        dataZoomShow = _ref.dataZoomShow;
      if (loading) {
        var _this$chart;
        (_this$chart = this.chart) === null || _this$chart === void 0 ? void 0 : _this$chart.showLoading();
        return;
      }
      var serie = {
        type: 'line',
        smooth: true,
        seriesLayoutBy: 'row'
      };
      var series = [];
      for (var i = 0; i < data.length - 1; i++) {
        series.push(serie);
      }
      total = (total || 0).toLocaleString();
      (_this$chart2 = this.chart) === null || _this$chart2 === void 0 ? void 0 : _this$chart2.hideLoading();
      (_this$chart3 = this.chart) === null || _this$chart3 === void 0 ? void 0 : _this$chart3.setOption({
        title: {
          left: 'left',
          text: this.title || 'Queries: ' + total,
          textStyle: {
            color: '#606266',
            fontSize: 14,
            lineHeight: 24,
            fontWeight: 'bold'
          }
        },
        color: ['#3fb1e3', '#d0648a'],
        legend: {
          bottom: 10
        },
        tooltip: {
          trigger: 'axis',
          // formatter:(params)=>{
          //   var res = params[0].name;
          //   for (var i = 0; i <params.length; i++) {
          //     res += "<br>"+params[i].marker+params[i].seriesName+"："+(params[i].data[i+1]);
          //   }
          //   return res;
          // }
          feature: {
            dataZoom: {
              yAxisIndex: 'none'
            },
            restore: {},
            saveAsImage: {}
          }
        },
        dataset: {
          // dimensions: ['product', 'queries'],
          source: data
        },
        grid: {
          left: 60,
          right: 40,
          top: 60,
          bottom: '70px'
        },
        xAxis: {
          type: 'category'
        },
        yAxis: {
          axisLabel: {
            show: true
          }
        },
        series: series,
        dataZoom: [{
          type: 'slider',
          show: dataZoomShow,
          start: 0,
          end: 100
        }]
      });
    }
  }
};